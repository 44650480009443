import React, {FormEvent, FunctionComponent, InputHTMLAttributes, useState} from 'react'
import ReactTooltip from 'react-tooltip'

import {IFormFieldProps} from './IFormFieldProps'
import iconMark from '../../../../assets/images/icons/mark-blue.svg'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'
import {validators} from './Validation'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string;
  name: string;
  classes?: string;
  defaultValue?: string;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  formatNumber?: { value: number | null, scale?: number };
  tooltip?: string;
  labelClasses?: string;
  inputClasses?: string;
  validator?: keyof typeof validators;
  errorMessage?: string
}

const Input: FunctionComponent<IProps> = ({
                                            id,
                                            name,
                                            classes,
                                            label,
                                            onChange,
                                            defaultValue,
                                            register,
                                            error,
                                            required,
                                            formatNumber,
                                            tooltip,
                                            labelClasses,
                                            inputClasses,
                                            validator,
                                            errorMessage,
                                            ...rest
                                          }) => {
  const [state, setState] = useState({isEditing: false})
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  function format(value: number | null, scale = 2): string | undefined {
    if (value) {
      const formatter = new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: scale,
      })
      return formatter.format(value)
    }
    return undefined
  }

  const validateField = (value: string): boolean => {
    if (!validator || value === '') {
      setErrorMsg(null)
      return true
    }

    const validate = validators[validator]

    if (validate) {
      const errorMessage = validate(value)
      if (errorMessage) {
        setErrorMsg(errorMessage)
        return false
      }

      setErrorMsg(null)
      return true
    }
    return true
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    validateField(value)

    if (onChange) {
      onChange(e)
    }
  }

  const inputElement = (
    <div className="form-control__input">
      <input
        defaultValue={defaultValue}
        {...rest}
        id={id}
        required={required}
        readOnly={rest.readOnly}
        title={rest.help}
        {...register(name, {
          validate: validator ? (value: string) => validateField(value) : undefined,
          value: formatNumber ? format(formatNumber.value, formatNumber.scale) : undefined,
          onChange: handleChange,
        })}
        className={inputClasses ?? ''}
      />
      {errorMsg && <FieldErrorMessage message={errorMsg}/>}
    </div>
  )

  return (
    <>
      <div className={classes ? `${classes}` : ''}>
        {label &&
          <div className="form-control">
            <div className="flex items-end">
              <ReactTooltip/>
              <label htmlFor={id}
                     className={(labelClasses ? labelClasses + ' form-control__label' : 'form-control__label') + (required ? ' mandatory-field ' : ' ')}>{label}</label>
              {tooltip && <div data-tip={tooltip}>
                <img src={iconMark} className="mark-tooltip" alt="help mark"/>
              </div>}
            </div>
            <div>
              {inputElement}
              {errorMessage && <FieldErrorMessage message={errorMessage}/>}
            </div>
          </div>
        }
        {!label && inputElement}
      </div>
    </>
  )
}

export default Input
