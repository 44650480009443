import {PersonInterface} from './Person'
import {BankInformation} from './BankInformation'
import {Relation} from './Relation'
import {FiscalityInterface} from './Fiscality'
import {BeneficialOwner} from './BeneficialOwner'
import {Notary, NotaryInterface} from "./Notary";
import {PartnerInterface} from "../Partner/Partner";
import {CustomerLegalRepresentativesInterface} from "./CustomerLegalRepresentatives";
import {Undivided} from "../Prospect/Undivided";
import {MoralLegalRepresentative} from "./MoralLegalRepresentative";
import {GeneralAddressInterface} from "../Address/GeneralAddress";

interface CustomerInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string |null
  enabled: boolean|null
  customerType: string|null
  statusCreatedAt: string|null
  status: string|null
  exitedAt: string|null
  blockedAt: string|null
  customerCode: string|null
  externalDepositaryCode: string|null
  externalCode: string|null
  recoveryCode: string|null
  legalStatus: string|null
  companyName: string|null
  siret: string|null
  orias: string|null
  kycCategoryValue: string|null
  kycCategoryDate: string|null
  kycLabft: string|null
  kycLabftDate: string|null
  kycProfession: string|null
  kycFatca: boolean|null
  kycCategoryValueSecond: string|null
  kycCategoryDateSecond: string|null
  kycLabftSecond: string|null
  kycLabftDateSecond: string|null
  kycProfessionSecond: string|null
  kycFatcaSecond: boolean|null
  moralType: string|null
  fiscality: FiscalityInterface|null
  persons: PersonInterface[]|null
  customerAddresses: GeneralAddressInterface[]|null
  bankInformations: BankInformation[]|null
  relations: Relation[]|null
  beneficialOwners: BeneficialOwner[]|null
  undivideds: Undivided[]|null
  moralLegalRepresentatives: MoralLegalRepresentative[]|null
  groupRelation: string|null
  groupRelationSecond: string|null
  notary: Notary|null
  partner: PartnerInterface|null
  sameFiscalAddress: boolean
  legalRepresentatives: CustomerLegalRepresentativesInterface[]|null
  lastNotepadAt: string| null
  countNotepad: number| null

}

class Customer implements CustomerInterface{

  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string |null
  public enabled: boolean|null
  public customerType: string|null
  public statusCreatedAt: string|null
  public status: string|null
  public exitedAt: string|null
  public blockedAt: string|null
  public customerCode: string|null
  public externalDepositaryCode: string|null
  public externalCode: string|null
  public recoveryCode: string|null
  public legalStatus: string|null
  public companyName: string|null
  public siret: string|null
  public orias: string|null
  public kycCategoryValue: string|null
  public kycCategoryDate: string|null
  public kycLabft: string|null
  public kycLabftDate: string|null
  public kycProfession: string|null
  public kycFatca: boolean|null
  public kycCategoryValueSecond: string|null
  public kycCategoryDateSecond: string|null
  public kycLabftSecond: string|null
  public kycLabftDateSecond: string|null
  public kycProfessionSecond: string|null
  public kycFatcaSecond: boolean|null
  public moralType: string|null
  public fiscality: FiscalityInterface|null
  public persons: PersonInterface[]|null
  public customerAddresses: GeneralAddressInterface[]|null
  public bankInformations: BankInformation[]|null
  public relations: Relation[]|null
  public beneficialOwners: BeneficialOwner[]|null
  public undivideds: Undivided[]|null
  public moralLegalRepresentatives: MoralLegalRepresentative[]|null
  public groupRelation: string|null
  public groupRelationSecond: string|null
  public notary: NotaryInterface|null
  public partner: PartnerInterface|null
  public sameFiscalAddress: boolean
  public legalRepresentatives: CustomerLegalRepresentativesInterface[]
  public lastNotepadAt: string| null
  public countNotepad: number| null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string |null,
    enabled: boolean|null,
    customerType: string|null,
    statusCreatedAt: string|null,
    status: string|null,
    exitedAt: string|null,
    blockedAt: string|null,
    customerCode: string|null,
    externalDepositaryCode: string|null,
    externalCode: string|null,
    recoveryCode: string|null,
    legalStatus: string|null,
    companyName: string|null,
    siret: string|null,
    orias: string|null,
    kycCategoryValue: string|null,
    kycCategoryDate: string|null,
    kycLabft: string|null,
    kycLabftDate: string|null,
    kycProfession: string|null,
    kycFatca: boolean|null,
    moralType: string|null,
    fiscality: FiscalityInterface|null,
    persons: PersonInterface[]|null,
    customerAddresses: GeneralAddressInterface[]|null,
    bankInformations: BankInformation[]|null,
    relations: Relation[]|null,
    beneficialOwners: BeneficialOwner[]|null,
    undivideds: Undivided[]|null,
    moralLegalRepresentatives: MoralLegalRepresentative[]|null,
    groupRelation: string|null,
    notary: NotaryInterface|null,
    partner: PartnerInterface|null,
    sameFiscalAddress: boolean,
    legalRepresentatives: CustomerLegalRepresentativesInterface[] = [],
    lastNotepadAt: string| null,
    countNotepad: number| null,
    kycCategoryValueSecond: string|null,
    kycCategoryDateSecond: string|null,
    kycLabftSecond: string|null,
    kycLabftDateSecond: string|null,
    kycProfessionSecond: string|null,
    kycFatcaSecond: boolean|null,
    groupRelationSecond: string|null
) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.enabled = enabled
    this.customerType = customerType
    this.statusCreatedAt = statusCreatedAt
    this.status = status
    this.exitedAt = exitedAt
    this.blockedAt = blockedAt
    this.customerCode = customerCode
    this.externalDepositaryCode = externalDepositaryCode
    this.externalCode = externalCode
    this.recoveryCode = recoveryCode
    this.legalStatus = legalStatus
    this.companyName = companyName
    this.siret = siret
    this.orias = orias
    this.kycCategoryValue = kycCategoryValue
    this.kycCategoryDate = kycCategoryDate
    this.kycLabft = kycLabft
    this.kycLabftDate = kycLabftDate
    this.kycProfession = kycProfession
    this.kycFatca = kycFatca
    this.kycCategoryValueSecond = kycCategoryValueSecond
    this.kycCategoryDateSecond = kycCategoryDateSecond
    this.kycLabftSecond = kycLabftSecond
    this.kycLabftDateSecond = kycLabftDateSecond
    this.kycProfessionSecond = kycProfessionSecond
    this.kycFatcaSecond = kycFatcaSecond
    this.moralType = moralType
    this.fiscality = fiscality
    this.persons = persons
    this.customerAddresses = customerAddresses
    this.bankInformations = bankInformations
    this.relations = relations
    this.beneficialOwners = beneficialOwners
    this.undivideds = undivideds
    this.moralLegalRepresentatives = moralLegalRepresentatives
    this.groupRelation = groupRelation
    this.groupRelationSecond = groupRelationSecond
    this.notary = notary
    this.partner = partner
    this.sameFiscalAddress = sameFiscalAddress
    this.legalRepresentatives = legalRepresentatives
    this.lastNotepadAt = lastNotepadAt
    this.countNotepad = countNotepad
  }
}

export {
  Customer
}
export type { CustomerInterface }
