export default class NumberFormat {
  static currencyFormat(number: number, showZeroDecimal = true, decimalPlaces = 2) {
    let result;

    if (showZeroDecimal && number % 1 === 0) {
      result = number
        .toFixed(decimalPlaces)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace('.', ',')
    } else {
      result = number
        .toFixed(decimalPlaces)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace('.', ',')
    }

    return result
  }
}