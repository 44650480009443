import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import blockScrollBody from '../../../util/BlockScroll';
import {
  setOpenAccountMoralLegalRepresentativeForm,
  setOpenPartnerAddAddressEvent
} from '../../../../store/component/event';
import getClassForOverlay from '../../../util/Sidebar';
import { MoralLegalRepresentative } from '../../../../../domain/Customer/MoralLegalRepresentative';
import { Person } from '../../../../../domain/Prospect/Person';
import { addMoralLegalRepresentative, updateMoralLegalRepresentative } from '../../../../store/component/customer';
import CityDatalist from '../../Datalist/City/CityDatalist';
import CountryDatalist from '../../Datalist/Country/CountryDatalist';
import { City } from '../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../domain/Referentiel/Country/Country';
import { CustomerInterface } from '../../../../../domain/Customer/Customer';
import InputView from '../../Elements/InputView';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { toastSuccess } from '../../../util/Toast';
import { usePrompt } from '../../../util/Navigation';
import SelectCustom from '../../Elements/Select';
import InputDate from '../../Elements/InputDate';
import CityAndPostcode from '../../Datalist/CityAndPostcode/CityAndPostcode';
import {Address} from "../../../../../domain/Address/Address";

interface IFormInput {
  id: string
  gerant: boolean
  ppe: boolean
  ppeCategory: string|null
  shareholding: string
  personId: string
  title: string|null
  status: string|null
  lastName: string|null
  maidenName: string|null
  firstName: string|null
  birthdate: string|null
  birthCountry: Country|null
  birthCityForeign: string|null
  birthCity: City|null
  nationality: Country|null
  addressId: string
  addressFirstLine: string|null
  addressSecondLine: string|null
  addressThirdLine: string|null
  zipcode: string|null
  city: City|null
  country: Country|null
}
type Props = {
  referential: ReferentielInterface
  customer?: CustomerInterface
}

const AddMoralLegalRepresentative: FunctionComponent<Props> = ({customer, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const methods = useForm();

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const openForm = useAppSelector((state) => state.event.openAccountMoralLegalRepresentativeForm)

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();
  const watchCivility = watch('title');
  const watchPpe = watch('ppe');

  useEffect(() => {
    blockScrollBody(openForm.show)

    if (openForm.moralLegalRepresentative) {
      setValue('id', openForm.moralLegalRepresentative?.id || '')
      setValue('gerant', openForm.moralLegalRepresentative?.person?.gerant || false)
      setValue('ppe', openForm.moralLegalRepresentative.ppe)
      setValue('ppeCategory', openForm.moralLegalRepresentative?.person?.ppeCategory || null)
      setValue('shareholding', openForm.moralLegalRepresentative.shareHolding)
      setValue('personId', openForm.moralLegalRepresentative?.person?.id || '')
      setValue('title', openForm.moralLegalRepresentative?.person?.title || null)
      setValue('status', openForm.moralLegalRepresentative.status)
      setValue('lastName', openForm.moralLegalRepresentative?.person?.lastname || null)
      setValue('maidenName', openForm.moralLegalRepresentative?.person?.maidenName || null)
      setValue('firstName', openForm.moralLegalRepresentative?.person?.firstname || null)
      setValue('birthdate', openForm.moralLegalRepresentative?.person?.birthdate || null)
      // setValue('birthCountry', openForm.moralLegalRepresentative?.person?.birthCountry || null)
      setValue('birthCity', openForm.moralLegalRepresentative?.person?.birthCity || null)
      // setValue('nationality', openForm.moralLegalRepresentative?.person?.nationality || null)
      setValue('addressId', openForm.moralLegalRepresentative?.person?.address?.id || '')
      setValue('addressFirstLine', openForm.moralLegalRepresentative?.person?.address?.address || null)
      setValue('addressSecondLine', openForm.moralLegalRepresentative?.person?.address?.address2 || null)
      setValue('addressThirdLine', openForm.moralLegalRepresentative?.person?.address?.address3 || null)
      setValue('zipcode', openForm.moralLegalRepresentative?.person?.address?.postcode || null)
      if (
        openForm.moralLegalRepresentative.person &&
        openForm.moralLegalRepresentative.person.address &&
        openForm.moralLegalRepresentative.person.address.city
      ) {
        setValue('city', openForm.moralLegalRepresentative.person.address.city || null)
      }

      if (
        openForm.moralLegalRepresentative.person &&
        openForm.moralLegalRepresentative.person.address &&
        openForm.moralLegalRepresentative.person.address.country
      ) {
        setValue('country', openForm.moralLegalRepresentative.person.address.country || null)
      }

    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('title', null)
      setValue('status', null)
      setValue('lastName', null)
      setValue('maidenName', null)
      setValue('firstName', null)
      setValue('birthdate', null)
      // setValue('birthCountry', null)
      setValue('birthCity', null)
      // setValue('nationality', null)
      setValue('addressId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('addressFirstLine', null)
      setValue('addressSecondLine', null)
      setValue('addressThirdLine', null)
      setValue('zipcode', null)
      setValue('city', null)
      setValue('country', null)
      setValue('ppe', false)
      setValue('gerant', false)
      setValue('shareholding', '')
    }
  }, [openForm])

  useEffect(() => {
    if (countryByDefault && openForm) {
      setValue('birthCountry', openForm.moralLegalRepresentative?.person?.birthCountry || countryByDefault[0]);
      setValue('nationality',  openForm.moralLegalRepresentative?.person?.nationality || countryByDefault[0]);
      setValue('country',  openForm?.moralLegalRepresentative?.person?.address?.country || countryByDefault[0]);
    }
  }, [countryByDefault, openForm])

  useEffect(() => {
    if (!watchPpe) {
      setValue('ppeCategory', '')
    }
  }, [watchPpe])

  function handleClose() {
    dispatch(setOpenAccountMoralLegalRepresentativeForm({show: false, moralLegalRepresentative: null}))
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const moralLegalRepresentative = new MoralLegalRepresentative(
      data.id,
      data.status,
      data.ppe,
      data.shareholding,
      new Person(
        data.personId,
        null,
        null,
        null,
        null,
        data.gerant,
        data.title,
        data.firstName,
        data.lastName,
        data.maidenName,
        data.birthdate,
        data.birthCity,
        data.birthCityForeign,
        data.birthCountry,
        data.nationality,
        data.ppe,
        data.ppeCategory,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        new Address(
          data.addressId,
          null,
          null,
          null,
          data.title || '',
          data.firstName  || '',
          data.lastName  || '',
          data.maidenName  || '',
          data.addressFirstLine || '',
          data.addressSecondLine  || '',
          data.addressThirdLine  || '',
          data.zipcode  || '',
          data.city || null,
          data.country || null,
          false,
          null,
          null
        ),
        null,
        null,
        null
      )
    )

    if (openForm.moralLegalRepresentative) {
      dispatch(updateMoralLegalRepresentative(moralLegalRepresentative))
      dispatch(setOpenPartnerAddAddressEvent({show: false, address: null}))
    } else {
      dispatch(addMoralLegalRepresentative(moralLegalRepresentative))
    }
    dispatch(setOpenAccountMoralLegalRepresentativeForm({show: false, moralRepresentative: null}))

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    toastSuccess(t('partner.notify.add-moral-legal-representative-success'))
    handleClose()
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  return (
    <>
      <div className={`overlay ${getClassForOverlay(openForm.show)}`}
           onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openForm.show ? 'sidebar--active' : ''}`}>
        <FormProvider {...methods} >
          <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-address-form">
            <div className="sidebar__content">
              <div className="title">{t('partner.form.moral-legal-representative.add')}</div>
              <div className="form-bloc__form flex-container">
                <div className="col-md-12">
                  <div className="form-control form-control--center">
                    <label htmlFor="ppe" className="form-control__label">
                        {t('account.form.moral-legal-representative.gerant')}
                    </label>
                    <div className="checkbox-toggle__wrapper">
                      <input type="checkbox" {...register('gerant')} id="gerant"
                             className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default" />
                      <label className="checkbox-toggle__button" htmlFor="gerant" />
                    </div>
                  </div>
                </div>
                {customer && customer.customerType === 'moral' &&
                  <InputView classes="col-md-12" label={t('account.form.moral-legal-representative.code')} defaultValue={openForm.moralLegalRepresentative?.person?.code || ''} readOnly={true} />
                }
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="title"
                           className="form-control__label">{t('account.form.moral-legal-representative.civility')}</label>
                    <div className="form-control__input">
                      <select {...register('title')}>
                        <option value="">{t('common.choice-option')}</option>
                        {referential.global.title_physic.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="status"
                           className="form-control__label">{t('partner.form.moral-legal-representative.status')}</label>
                    <div className="form-control__input">
                      <select {...register('status')}>
                        <option value="">{t('common.choice-option')}</option>
                        {referential.prospect.status.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="lastName"
                           className="form-control__label">{t('partner.form.moral-legal-representative.lastname')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('lastName')} />
                    </div>
                  </div>
                </div>
                {watchCivility === 'mrs' &&
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="maidenName"
                           className="form-control__label">{t('partner.form.moral-legal-representative.maiden-name')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('maidenName')} />
                    </div>
                  </div>
                </div>
                }
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="firstName"
                           className="form-control__label">{t('partner.form.moral-legal-representative.firstname')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('firstName')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="birthdate"
                           className="form-control__label">{t('partner.form.moral-legal-representative.date-of-birth')}</label>
                    <div className="form-control__input">
                      <InputDate id="birthdate"
                                 name="birthdate"
                                 register={register}
                                 control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="birthCountry"
                           className="form-control__label">{t('partner.form.moral-legal-representative.country-of-birth')}</label>
                    <div className="form-control__input">
                      <CountryDatalist control={control} name="birthCountry" defaultValue={getValues('birthCountry')}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="nationality"
                           className="form-control__label">{t('partner.form.moral-legal-representative.nationality')}</label>
                    <div className="form-control__input">
                      <CountryDatalist control={control} name="nationality" defaultValue={getValues('nationality')}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <CityDatalist control={control}
                                setValue={setValue}
                                name="birthCity"
                                defaultValue={getValues('birthCity')}
                                labelContent={t('partner.form.moral-legal-representative.city-of-birth')}
                                country={getValues('birthCountry')}
                                classes="flex"
                                mode="col"
                  />
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="addressFirstLine"
                           className="form-control__label">{t('partner.form.moral-legal-representative.address-first-line')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('addressFirstLine')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="addressSecondLine"
                           className="form-control__label">{t('partner.form.moral-legal-representative.address-second-line')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('addressSecondLine')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="addressThirdLine"
                           className="form-control__label">{t('partner.form.moral-legal-representative.address-third-line')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('addressThirdLine')} />
                    </div>
                  </div>
                </div>
                <CityAndPostcode control={control}
                                 nameCity="city"
                                 namePostcode="zipcode"
                                 country={getValues('country')}
                                 register={register}
                                 setValue={setValue}
                                 getValues={getValues}
                                 mode="row"
                                 classes="col-md-12 u-px0"
                />
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="country"
                           className="form-control__label">{t('partner.form.moral-legal-representative.country')}</label>
                    <div className="form-control__input">
                        <CountryDatalist control={control} name="country" defaultValue={getValues('country')}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control form-control--center">
                    <label htmlFor="ppe"
                           className="form-control__label">{t('partner.form.moral-legal-representative.ppe')}</label>
                    <div className="checkbox-toggle__wrapper">
                      <input type="checkbox" {...register('ppe')} id="ppe"
                             className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default" />
                      <label className="checkbox-toggle__button" htmlFor="ppe" />
                    </div>
                  </div>
                </div>
                {watchPpe &&
                    <div className="col-md-12">
                        <div className="form-control--label-max-content">
                            <SelectCustom classes="" id="ppeCategory" name="ppeCategory" options={referential.customer.ppe_category} register={register} label={t('account.form.manager.managerPpeCategory')} />
                        </div>
                    </div>
                }
                <div className="col-md-12">
                  <div className="form-control form-control--center">
                    <label htmlFor="shareholding"
                           className="form-control__label">{t('partner.form.moral-legal-representative.shareholding')}</label>
                    <div className="form-control__input">
                      <input type="number" {...register('shareholding')} id="shareholding" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="sidebar__footer">
              <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
              <button type="button" className="button button--ink-2 button--ink-2--outline"
                      onClick={() => handleClose()}>{t('common.cancel')}</button>
            </footer>
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default AddMoralLegalRepresentative
