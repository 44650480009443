import {FunctionComponent} from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {CustomerInterface} from '../../../../../domain/Customer/Customer'

import '../../../../../assets/styles/components/_tab.scss'

type Props = {
  customer?: CustomerInterface
  isLectureMode: boolean
}

const TabCustomerEditCorporation: FunctionComponent<Props> = ({customer, isLectureMode}) => {
  const {t} = useTranslation()
  const { customerType } = useParams()

  return (
    <div className={`tab tab--customer-add`}>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-general-information`)}/${customer?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-general-information')}
      </NavLink>
      {customer?.moralType === 'business' &&
        <NavLink
          to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-beneficial-owner`)}/${customer?.id}`}
          className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
        >
          {t('account.add.tab-beneficial-owner')}
        </NavLink>
      }
      {customer?.moralType === 'joint_possession' &&
        <NavLink
          to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-indivisaire`)}/${customer?.id}`}
          className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
        >
          {t('account.add.tab-indivisaire')}
        </NavLink>
      }
      {customer?.moralType === 'other' &&
          <NavLink
              to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-moral-legal-representative`)}/${customer?.id}`}
              className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
          >
            {t('account.add.tab-moral-legal-representative')}
          </NavLink>
      }
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-addresses`)}/${customer?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-addresses')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-bank-details`)}/${customer?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-bank-details')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-investment-information`)}/${customer?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-investment-information')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-particularities`)}/${customer?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-particularities')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-notary`)}/${customer?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-notary')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-pledge`)}/${customer?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-pledge')}
      </NavLink>
    </div>
  )
}

export default TabCustomerEditCorporation
